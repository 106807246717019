var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chat-profile-sidebar",
    class: {
      'show': _vm.shallShowUserProfileSidebar
    }
  }, [_c('header', {
    staticClass: "chat-profile-header"
  }, [_c('span', {
    staticClass: "close-icon"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "XIcon"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close-sidebar');
      }
    }
  })], 1), _c('div', {
    staticClass: "header-profile-sidebar"
  }, [_c('div', {
    staticClass: "avatar box-shadow-1 avatar-xl avatar-border"
  }, [_c('b-avatar', {
    attrs: {
      "size": "70",
      "src": _vm.profileUserData.avatar
    }
  }), _c('span', {
    staticClass: "avatar-status-xl",
    class: "avatar-status-".concat(_vm.profileUserData.status)
  })], 1), _c('h4', {
    staticClass: "chat-user-name"
  }, [_vm._v(" " + _vm._s(_vm.profileUserData.fullName) + " ")]), _c('span', {
    staticClass: "user-post text-capitalize"
  }, [_vm._v(_vm._s(_vm.profileUserData.role))])])]), _c('vue-perfect-scrollbar', {
    staticClass: "profile-sidebar-area scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('h6', {
    staticClass: "section-label mb-1"
  }, [_vm._v(" About ")]), _c('div', {
    staticClass: "about-user"
  }, [_c('b-form-textarea', {
    attrs: {
      "placeholder": "Something about yourself...",
      "rows": "5"
    },
    model: {
      value: _vm.profileUserData.about,
      callback: function callback($$v) {
        _vm.$set(_vm.profileUserData, "about", $$v);
      },
      expression: "profileUserData.about"
    }
  })], 1), _c('h6', {
    staticClass: "section-label mb-1 mt-3"
  }, [_vm._v(" Status ")]), _c('b-form-radio-group', {
    attrs: {
      "id": "user-status-options",
      "stacked": ""
    },
    model: {
      value: _vm.profileUserData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.profileUserData, "status", $$v);
      },
      expression: "profileUserData.status"
    }
  }, _vm._l(_vm.userStatusOptions, function (option) {
    return _c('b-form-radio', {
      key: option.value,
      class: "custom-control-".concat(option.radioVariant),
      attrs: {
        "value": option.value
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  }), 1), _c('h6', {
    staticClass: "section-label mb-1 mt-2"
  }, [_vm._v(" Settings ")]), _vm.profileUserData.settings ? _c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "d-flex justify-content-between align-items-center mb-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CheckSquareIcon",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Two-step Verification")])], 1), _c('b-form-checkbox', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.profileUserData.settings.isTwoStepAuthVerificationEnabled,
      callback: function callback($$v) {
        _vm.$set(_vm.profileUserData.settings, "isTwoStepAuthVerificationEnabled", $$v);
      },
      expression: "profileUserData.settings.isTwoStepAuthVerificationEnabled"
    }
  })], 1), _c('li', {
    staticClass: "d-flex justify-content-between align-items-center mb-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "BellIcon",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Notification")])], 1), _c('b-form-checkbox', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.profileUserData.settings.isNotificationsOn,
      callback: function callback($$v) {
        _vm.$set(_vm.profileUserData.settings, "isNotificationsOn", $$v);
      },
      expression: "profileUserData.settings.isNotificationsOn"
    }
  })], 1), _c('li', {
    staticClass: "mb-1 d-flex align-items-center cursor-pointer"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "UserIcon",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Invite Friends")])], 1), _c('li', {
    staticClass: "d-flex align-items-center cursor-pointer"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "TrashIcon",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Delete Account")])], 1)]) : _vm._e(), _c('div', {
    staticClass: "mt-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Logout ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }